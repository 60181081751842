<template>
  <div class="device-details">
    <Header />
    <div class="container w-1200">
      <div class="left-area">
        <div class="device-intro">
          <div class="preview">
            <div class="big-pic">
              <img :src="newimg" alt="" />
            </div>
            <div class="small-pic">
              <i class="icon btn-prev" @click="left()"></i>

              <div
                class="small-pic-item"
                :class="{ selected: index == a }"
                v-for="(item, index) in newlist"
                :key="index"
                @click="replacebtn(index)"
              >
                <img :src="item" alt="" />
              </div>

              <i class="icon btn-next" @click="right()"></i>
            </div>
          </div>
          <div class="info">
            <div class="name">{{ list[0].name }}</div>
            <div class="summary">
              <dl>
                <dt>租金：</dt>
                <dd
                  class="price"
                  v-for="(item, index) in list[0].device_rental_fee"
                  :key="index"
                >
                  <small>¥</small><big>{{ item.rental_fee }}</big
                  >/{{ item.rental_type }}
                </dd>
              </dl>
              <dl>
                <dt>类型：</dt>
                <dd>{{ list[0].type_name }}</dd>
              </dl>
              <dl>
                <dt>地区：</dt>
                <dd>{{ list[0].address }}</dd>
              </dl>
            </div>
            <div class="btn-group">
              <div class="btn-item">
                <i class="f-icon icon-customer-service-outline"></i>客服
              </div>
              <div class="btn-item" @click="Collection()">
                <i
                  class="f-icon icon-collect"
                  :class="{ selected: flagres }"
                ></i
                >收藏
              </div>
              <div class="btn-item"><i class="f-icon icon-share"></i>分享</div>
            </div>
            <div class="addnumber">
              <div class="type">
                请选择租聘方式
                <div
                  class="addtype"
                  id="addtype"
                  v-for="(item, index) in list[0].device_rental_fee"
                  :key="index"
                  @click="addtype(index)"
                >
                  {{ item.rental_fee }}/{{ item.rental_type }}
                </div>
              </div>
              <div class="time">
                请选择时间
                <el-input-number
                  type="number"
                  :step="1"
                  step-strictly
                  v-model="addtime"
                  @change="handleChange"
                  :min="1"
                  :max="999"
                  label="请选择时间"
                ></el-input-number
                >{{ list[0].device_rental_fee[idnum].rental_type }}
              </div>
              <div class="num">
                请选择数量
                <el-input-number
                  v-model="addnum"
                  :step="1"
                  step-strictly
                  @change="handleChange"
                  :min="1"
                  :max="list[0].num"
                  label="请选择数量"
                ></el-input-number
                >台
              </div>
            </div>
            <div class="f-flex">
              <div
                class="btn-add-shop-cart"
                v-show="flag"
                @click="shopCartAdd()"
              >
                加入购物车
              </div>
              <div class="btn-reserve" v-show="flag" @click="deviceReserve()">
                设备预定
              </div>
            </div>
          </div>
        </div>
        <div class="description">
          <div class="description-header">
            <span class="title">详情描述</span>
          </div>
          <!-- <div class="parameter">
               <ul>
                 <li>品牌：卡特彼勒</li>
                 <li>类型：{{list[0].apply_working}}</li>
                 <li>备件：{{list[0].device_config_id}}</li>
               </ul>
             </div> -->
          <div class="detail-content">
            <p v-html="list[0].detail_info"></p>

            <!-- <p>最初挖掘机是手动的，从发明到2013年已经有一百三十多年了，期间经历了由蒸汽驱动斗回转挖掘机到电力驱动和内燃机驱动回转挖掘机、应用机电液一体化技术的全自动液压挖掘机的逐步发展过程。第一台液压挖掘机由法国波克兰工厂发明成功。由于液压技术的应用，20世纪40年代有了在拖拉机上配装液压反铲地悬挂式挖掘机。1951 年，第一台全液压反铲挖掘机由位于法国的 Poclain（波克兰）工厂推出，从而在挖掘机的技术发展领域开创了全新空间，20世纪50年代初期和中期相继研制出拖式全回转液压挖掘机和履带式全液压挖掘机。初期试制的液压挖掘机是采用飞机和机床的液压技术，缺少适用于挖掘机各种工况的液压元件，制造质量不够稳定，配套件也不齐全。从20世纪60年代起，液压挖掘机进入推广和蓬勃发展阶段，各国挖掘机制造厂和品种增加很快，产量猛增。1968-1970年间，液压挖掘机产量已占挖掘机总产量的83%，已接近100%。</p> -->
            <!-- <p>第一代挖掘机：电动机、内燃机的出现，使挖掘机有了先进而合适的电动装置，于是各种挖掘机产品相继诞生。1899年，第一台电动挖掘机出现了。第一次世界大战后，柴油发动机也应用在挖掘机上，这种柴油发动机（或电动机）驱动的机械式挖掘机是第一代挖掘机。</p> -->
            <p><img :src="list[0].detail_img" alt="" /></p>
          </div>
        </div>
      </div>
      <div class="similar-device">
        <div class="similar-device-title"><span>相似设备</span></div>
        <div class="base-list">
          <div class="card">
            <div
              class="card-item"
              v-for="(item, index) in list1"
              :key="index"
              @click="$router.push({ name: 'deviceDetails' })"
            >
              <img :src="item.show_img" class="pic" alt="" />
              <div class="body">
                <div class="price">
                  <small>¥{{ item.device_rental_fee[0].rental_fee }}</small
                  ><big></big>/{{ item.device_rental_fee[0].rental_type }}
                </div>
                <div class="content">
                  {{ item.detail_info }}
                </div>
                <div class="factory">
                  <div class="factory-name">
                    <i class="f-icon icon-factory"></i>{{ item.name }}
                  </div>
                  <div class="location">
                    {{ item.address }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <right-nav />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import RightNav from "@/components/rightNav";
import {
  getservelist,
  getserach,
  getCollection,
  regirer,
  getdelete,
} from "@/api/over.js";
import kits from "@/kits/index";
import { shopCart_add } from "@/api/shopCart";
import { getcollect } from "../../api/over";
// import { response } from "express";
export default {
  components: {
    Header,
    Footer,
    RightNav,
  },
  data() {
    return {
      list: null,
      list1: "",
      addnum: 1,
      addtime: 1,
      device_lease_id: "",
      rental_fee_id: 2,
      rental_time: 5,
      rental_num: 20,
      type_id: "",
      newlist: [],
      newimg: null,
      a: 0,
      i: 0,
      b: 0,
      idnum: 0,
      consumer_id: "",
      flag: false,
      is_supplier: "",
      is_procet: "",
      flagres: false,
      is_collect: "",
    };
  },
  mounted() {
    this.device_lease_id = this.$route.query.id;
    console.log("this is ", this.device_lease_id);
    regirer().then((res) => {
        console.log("this is regirer ----------------res:", res);
      this.is_supplier = res.data.is_supplier;
      this.is_procet = res.data.is_procet;
      if (this.is_procet == 1  ) {
        this.flag = true;
      } else {
        this.flag = false;
      }
    });
    getservelist({
      inputs: { device_lease_id: this.device_lease_id },
    }).then((res) => {
      this.list = res.data;
      this.list[0].addtime = this.addtime;
      this.list[0].addnum = this.addnum;
      this.list[0].selectedfee = "";
      this.list[0].selectedtype = "";
      this.is_collect = res.data[0].is_collect;
      if (this.is_collect == 0) {
        this.flagres = false;
      } else {
        this.flagres = true;
      }
      const logstids= JSON.parse(localStorage.getItem('user'))
      const logstid=logstids.id
      if(logstid==this.list[0].consumer_id){
        this.flag = false;
      }
      else
      {
      true
      }

      this.rental_fee_id = res.data[0].rental_fee_id;
      this.rental_time = res.data[0].rental_time;
      this.rental_num = res.data[0].rental_num;
      this.type_id = res.data[0].type_id;
      this.consumer_id = res.data[0].consumer_id;
      console.log("this.type_id", this.type_id);
      this.list.map((item) => {
        item.device_rental_fee = JSON.parse(item.device_rental_fee_json);
        // item.device_rental_fee = JSON.parse(item.device_rental_fee_json)
      });
      this.list[0].device_rental_fee = JSON.parse(
        this.list[0].device_rental_fee_json
      );
      this.list[0].other_imgurl = JSON.parse(this.list[0].other_img);
      // console.log( this.list[0].other_imgurl);
      // this.list[0].other_imgurl.forEach(e => {
      //    e = kits.img.url(e)
      // });
      this.list[0].show_img = kits.img.url(this.list[0].show_img);
      for (let index = 0; index < this.list[0].other_imgurl.length; index++) {
        this.list[0].other_imgurl[index] = kits.img.url(
          this.list[0].other_imgurl[index]
        );
      }
      // this.list[0].working = JSON.parse(this.list[0].apply_working);
      this.list.forEach((e) => {
        for (var i = 0; i < e.device_rental_fee.length; i++) {
          // console.log('abc',e.device_rental_fee[i].rental_type);
          if (e.device_rental_fee[i].rental_type == 0) {
            e.device_rental_fee[i].rental_type = "日";
          } else if (e.device_rental_fee[i].rental_type == 1) {
            e.device_rental_fee[i].rental_type = "月";
          } else if (e.device_rental_fee[i].rental_type == 2) {
            e.device_rental_fee[i].rental_type = "半年";
          } else if (e.device_rental_fee[i].rental_type == 3) {
            e.device_rental_fee[i].rental_type = "年";
          }
        }
      });
      this.newimg = this.list[0].show_img;
      this.newlist.push(this.list[0].show_img);
      this.newlist.push(...this.list[0].other_imgurl);
      console.log("this.list", this.list);
    });

    getserach({
      inputs: { type_id: this.type_id },
      pagination: { pageNumber: 1, pageSize: 16 },
    }).then((res) => {
      let list = [];
      let data = res.data ? res.data : [];
      if (data) list = data.list;
      list.map((item) => {
        item.device_rental_fee = JSON.parse(item.device_rental_fee_json);
        // item.device_rental_fee = JSON.parse(item.device_rental_fee_json)
      });

      this.list1 = list;
      this.list1.forEach((e) => {
        for (var i = 0; i < e.device_rental_fee.length; i++) {
          if (e.device_rental_fee[i].rental_type == 0) {
            e.device_rental_fee[i].rental_type = "日";
          } else if (e.device_rental_fee[i].rental_type == 1) {
            e.device_rental_fee[i].rental_type = "月";
          } else if (e.device_rental_fee[i].rental_type == 2) {
            e.device_rental_fee[i].rental_type = "半年";
          } else if (e.device_rental_fee[i].rental_type == 3) {
            e.device_rental_fee[i].rental_type = "年";
          }
        }
      });

      console.log("========", this.list1);
    });

    getcollect();
  },
  methods: {
    shopCartAdd() {
      if (this.list[0].selectedfee == "") {
        this.$message({
          message: "请选择租凭方式",
          type: "warning",
        });
      } else {
        let rental_type = 0;
        this.list[0].selectedtype === "日"
          ? (rental_type = 0)
          : this.list[0].selectedtype === "月"
          ? (rental_type = 1)
          : this.list[0].selectedtype === "半年"
          ? (rental_type = 2)
          : (rental_type = 3);
        shopCart_add({
          inputs: {
            device_lease_id: this.device_lease_id,
            consumer_id: this.list[0].consumer_id,
            rental_type: rental_type,
            rental_time: this.addtime,
            rental_num: this.addnum,
          },
        }).then((res) => {
          if (res.status === 200) {
            if (res.data !== 0) {
              this.$message({
                message: "添加成功",
                type: "success",
              });
            } else {
              this.$message({
                message: "添加失败",
                type: "error",
              });
            }
          } else {
            this.$message({
              message: "网络异常，请稍后再添加",
              type: "error",
            });
          }
        });
      }
    },
    deviceReserve() {
      if (this.list[0].selectedfee == "") {
        this.$message({
          message: "请选择租凭方式",
          type: "warning",
        });
      } else {
        let list = this.list[0];
        this.list[0].show_imgs = this.list[0].show_img.split("=");
        this.list[0].show_img = this.list[0].show_imgs[1];
        console.log("this.list[0].show_img", this.list);
        let checkedList = [
          {
            company_name: list.company_name,
            item: this.list,
          },
        ];
        checkedList[0].item[0].rental_fee = `${list.selectedfee}/${list.selectedtype}`;
        checkedList[0].item[0].rental_num = this.addnum;
        checkedList[0].item[0].rental_time = this.addtime;
        checkedList[0].item[0].rental_type = list.selectedtype;
        checkedList[0].item[0].sum =
          this.addnum * this.addtime * list.selectedfee;
        localStorage.setItem("deviceReserve_Key", JSON.stringify(checkedList));
        this.$router.push({ name: "deviceReserve" });
      }
    },
    Collection() {
      if (this.flagres == true) {
        console.log("this.device_lease_id", this.device_lease_id);
        getdelete({
          inputs: { device_lease_id: this.device_lease_id },
        }).then((resm) => {
          if (resm.status == "200") {
            this.flagres = false;
            this.$message({
              message: "取消成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "取消失败",
              type: "error",
            });
          }
        });
      } else {
        getCollection({
          inputs: {
            consumer_id: this.consumer_id,
            device_lease_id: this.device_lease_id,
          },
        }).then((res) => {
          if (res.status == "200") {
            this.flagres = true;
            this.$message({
              message: "添加成功",
              type: "success",
            });
          } else {
            this.flagres = false;
            this.$message({
              message: "添加失败",
              type: "error",
            });
          }
        });
      }
    },
    handleChange() {
      this.list[0].addtime = this.addtime;
      this.list[0].addnum = this.addnum;
    },
    addtype(idx) {
      this.list[0].selectedfee = this.list[0].device_rental_fee[idx].rental_fee;
      this.list[0].selectedtype =
        this.list[0].device_rental_fee[idx].rental_type;
      var types = document.getElementsByClassName("addtype");
      var addtype = document.getElementsByClassName("addtype");
      console.log("bbbb", types);
      for (var i = 0; i < types.length; i++) {
        types[i].style.border = "1px solid black";
      }
      addtype[idx].style.border = "2px solid red";
      this.idnum = idx;
    },
    //     handlerChange(e) {
    //   console.log(e.data * 1);
    //   if (isNaN(e.data * 1) == true) {
    //     this.$message.error('只能为整数')
    //   }
    // },
    replacebtn(ids) {
      console.log(" this.list[0].show_imgrep", this.newlist);
      this.newimg = this.newlist[ids];
      this.a = ids;
      this.i = ids;
      console.log(" this.list[0].show_imgrep", this.newimg);
    },
    left() {
      if (this.i == 0) {
        this.i = this.newlist.length - 1;
      } else {
        this.i--;
      }
      console.log(this.i);
      this.replacebtn(this.i);
    },
    right() {
      this.i++;
      if (this.i == this.newlist.length) {
        this.i = 0;
      }
      console.log(this.i);
      this.replacebtn(this.i);
    },
  },
};
</script>

<style scoped>
.type {
  display: flex;
  text-align: center;
}

.addtype {
  width: 80px;
  height: 40px;
  line-height: 40px;
  margin-left: 20px;
  border-color: 2px solid black;
  border: 1px solid black;
}

.time {
  margin-top: 2%;
}

.num {
  margin-top: 2%;
}

.small-pic {
  justify-content: center;
  align-items: center;
}
</style>
